import { Injectable } from "@angular/core";
import { NewsItemDto } from "../contracts/news-item.dto";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private translateService: TranslateService) {

  }

  private items: { lang: string, data: NewsItemDto[] }[] = [
    {
      lang: 'en',
      data: [
        {
          title: "BMW M4 GT4 at BMW TechWorks Romania",
          slug: 'bmw-m4-gt4-at-bmw-techworks-romania',
          subTitle: "Subtitle",
          description: "<b>• Desc<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
          imgAlt: "BMW M4 GT4 at BMW TechWorks Romania",
          imgSrc: "/assets/images/release.webp",
          private: true
        },
        {
          title: "BMW TechWorks Romania: BMWGroup and NTT DATA accelerate digital transformation with new IT hub",
          slug: 'bmw-04-24',
          subTitle: "THU, 04 JULY 2024",
          description: "<b>• Made in Europe:</b> New Romanian IT hub develops central building blocks for IT projects in the EU<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
          imgAlt: "BMW TechWorks Romania Opening Event",
          imgSrc: "/assets/images/release.webp",
          files: [
            {
              name: "BMWGroup_and_NTT_DATA_accelerate_digital_transformation_with_new_IT_hub_1.pdf",
              title: "Download EN press release here"
            }, {
              name: "Comunicat_de_presa.pdf",
              title: "Download RO press release here"
            }
          ]
        }, {
          title: "BMW Group and NTT DATA Romania sign Joint Venture contract",
          slug: 'jv-26-06-24',
          subTitle: "TUE, 05 MARCH 2024",
          description: "• Joint Venture planned in the up-and-coming university town of Cluj-Napoca.<br/>• Start with 120 talented software developers.<br/>• Focus on the development and operation of IT solutions for the European region.",
          imgAlt: "Joint Venture image",
          imgSrc: "/assets/images/jointVenture.webp",
        },
        {
          title: "Fueled by Passion: BMW TechWorks Romania at Crosul Companiilor",
          slug: 'pass-22-09-24',
          subTitle: "SUN, 22 SEPTEMBER 2024",
          description: "As BMW TechWorks Romania continues to grow, sports and well-being will remain central to the company’s values. On October 19th, 28 people from BMW TechWorks Romania took on Crosul Companiilor, with colleagues cheering them on from the sidelines. ",
          imgAlt: "Crosul Companiilor image",
          imgSrc: "./news/cros-1/optimized_CrosulCompaniilor.jpg/optimized_file_CrosulCompaniilor.webp",
        },
        {
          title: "BMW TechWorks Romania Attends DevTalks Cluj",
          slug: 'devtalk-26-10-24',
          subTitle: "SAT, 26 OCTOBER 2024",
          description: "On October 26th, BMW TechWorks Romania had the opportunity to participate at DevTalks Cluj, one of Romania’s leading tech conferences. The Project Leads were on hand to connect with software developers, share insights about the company's role as an IT hub for BMW Group, and highlight the innovative projects taking place in Cluj-Napoca. ",
          imgAlt: "Devtalk image",
          imgSrc: "./news/dev-talk-1/optimized_DevTalks (1).jpeg/optimized_file_DevTalks (1).webp",
        },
        {
          title: "Celebrating Milestones Oktoberfest-Themed Town Hall",
          slug: 'oktfest-03-10-24',
          subTitle: "THU, 03 OCTOBER 2024",
          description: " On October 3rd, BMW TechWorks Romania hosted its third Town Hall, offering employees a chance to reflect on its journey as BMW Group's newest IT Hub. Employees got the chance to connect with the Cluster Heads and expats responsible for delivery operations, as well as meet the key leaders steering corporate functions like Compliance, Marketing, HR, and Finance. This allowed for a deeper understanding of our structure and delivery model. ",
          imgAlt: "TownHall image",
          imgSrc: "./news/townhall/optimized_TownHall.jpeg/optimized_file_TownHall.webp",
        }
      ]
    }, {
      lang: 'de',
      data: [
        {
          title: "BMW M4 GT4 at BMW TechWorks Romania",
          slug: 'bmw-m4-gt4-at-bmw-techworks-romania',
          subTitle: "Subtitle",
          description: "<b>• Desc<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
          imgAlt: "BMW M4 GT4 at BMW TechWorks Romania",
          imgSrc: "/assets/images/release.webp",
          private: true
        },
        {
          title: "BMW TechWorks Romania: BMWGroup and NTT DATA accelerate digital transformation with new IT hub",
          slug: 'bmw-04-24',
          subTitle: "THU, 04 JULY 2024",
          description: "<b>• Made in Europe:</b> New Romanian IT hub develops central building blocks for IT projects in the EU<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
          imgAlt: "BMW TechWorks Romania Opening Event",
          imgSrc: "/assets/images/release.webp",
          files: [
            {
              name: "BMWGroup_and_NTT_DATA_accelerate_digital_transformation_with_new_IT_hub_1.pdf",
              title: "Download EN press release here"
            }, {
              name: "Comunicat_de_presa.pdf",
              title: "Download RO press release here"
            }
          ]
        }, {
          title: "BMW Group and NTT DATA Romania sign Joint Venture contract",
          slug: 'jv-26-06-24',
          subTitle: "TUE, 05 MARCH 2024",
          description: "• Joint Venture planned in the up-and-coming university town of Cluj-Napoca.<br/>• Start with 120 talented software developers.<br/>• Focus on the development and operation of IT solutions for the European region.",
          imgAlt: "Joint Venture image",
          imgSrc: "/assets/images/jointVenture.webp",
        },
        {
          title: "Fueled by Passion: BMW TechWorks Romania at Crosul Companiilor",
          slug: 'pass-22-09-24',
          subTitle: "SUN, 22 SEPTEMBER 2024",
          description: "As BMW TechWorks Romania continues to grow, sports and well-being will remain central to the company’s values. On October 19th, 28 people from BMW TechWorks Romania took on Crosul Companiilor, with colleagues cheering them on from the sidelines. ",
          imgAlt: "Crosul Companiilor image",
          imgSrc: "/assets/images/jointVenture.webp",
        },
        {
          title: "BMW TechWorks Romania Attends DevTalks Cluj",
          slug: 'devtalk-26-10-24',
          subTitle: "SAT, 26 OCTOBER 2024",
          description: "On October 26th, BMW TechWorks Romania had the opportunity to participate at DevTalks Cluj, one of Romania’s leading tech conferences. The Project Leads were on hand to connect with software developers, share insights about the company's role as an IT hub for BMW Group, and highlight the innovative projects taking place in Cluj-Napoca. ",
          imgAlt: "Devtalk image",
          imgSrc: "/assets/images/jointVenture.webp",
        },
        {
          title: "Celebrating Milestones Oktoberfest-Themed Town Hall",
          slug: 'oktfest-03-10-24',
          subTitle: "THU, 03 OCTOBER 2024",
          description: " On October 3rd, BMW TechWorks Romania hosted its third Town Hall, offering employees a chance to reflect on its journey as BMW Group's newest IT Hub. Employees got the chance to connect with the Cluster Heads and expats responsible for delivery operations, as well as meet the key leaders steering corporate functions like Compliance, Marketing, HR, and Finance. This allowed for a deeper understanding of our structure and delivery model. ",
          imgAlt: "TownHall image",
          imgSrc: "/assets/images/jointVenture.webp",
        }
      ]
    }, {
      lang: 'ro',
      data: [
        {
          title: "BMW M4 GT4 at BMW TechWorks Romania",
          slug: 'bmw-m4-gt4-at-bmw-techworks-romania',
          subTitle: "Subtitle",
          description: "<b>• Desc<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
          imgAlt: "BMW M4 GT4 at BMW TechWorks Romania",
          imgSrc: "/assets/images/release.webp",
          private: true
        },
        {
          title: "BMW TechWorks Romania: BMWGroup and NTT DATA accelerate digital transformation with new IT hub",
          slug: 'bmw-04-24',
          subTitle: "THU, 04 JULY 2024",
          description: "<b>• Made in Europe:</b> New Romanian IT hub develops central building blocks for IT projects in the EU<br/><b>• Dynamic growth:</b> doubling the number of employees and double-digit million turnover in the very first year<br/><b>• Innovation hub:</b> The university city of Cluj-Napoca offers a dynamic environment for innovation and IT talent",
          imgAlt: "BMW TechWorks Romania Opening Event",
          imgSrc: "/assets/images/release.webp",
          files: [
            {
              name: "BMWGroup_and_NTT_DATA_accelerate_digital_transformation_with_new_IT_hub_1.pdf",
              title: "Download EN press release here"
            }, {
              name: "Comunicat_de_presa.pdf",
              title: "Download RO press release here"
            }
          ]
        }, {
          title: "BMW Group and NTT DATA Romania sign Joint Venture contract",
          slug: 'jv-26-06-24',
          subTitle: "TUE, 05 MARCH 2024",
          description: "• Joint Venture planned in the up-and-coming university town of Cluj-Napoca.<br/>• Start with 120 talented software developers.<br/>• Focus on the development and operation of IT solutions for the European region.",
          imgAlt: "Joint Venture image",
          imgSrc: "/assets/images/jointVenture.webp",
        },
        {
          title: "Fueled by Passion: BMW TechWorks Romania at Crosul Companiilor",
          slug: 'pass-22-09-24',
          subTitle: "SUN, 22 SEPTEMBER 2024",
          description: "As BMW TechWorks Romania continues to grow, sports and well-being will remain central to the company’s values. On October 19th, 28 people from BMW TechWorks Romania took on Crosul Companiilor, with colleagues cheering them on from the sidelines. ",
          imgAlt: "Crosul Companiilor image",
          imgSrc: "/assets/images/jointVenture.webp",
        },
        {
          title: "BMW TechWorks Romania Attends DevTalks Cluj",
          slug: 'devtalk-26-10-24',
          subTitle: "SAT, 26 OCTOBER 2024",
          description: "On October 26th, BMW TechWorks Romania had the opportunity to participate at DevTalks Cluj, one of Romania’s leading tech conferences. The Project Leads were on hand to connect with software developers, share insights about the company's role as an IT hub for BMW Group, and highlight the innovative projects taking place in Cluj-Napoca. ",
          imgAlt: "Devtalk image",
          imgSrc: "/assets/images/jointVenture.webp",
        },
        {
          title: "Celebrating Milestones Oktoberfest-Themed Town Hall",
          slug: 'oktfest-03-10-24',
          subTitle: "THU, 03 OCTOBER 2024",
          description: " On October 3rd, BMW TechWorks Romania hosted its third Town Hall, offering employees a chance to reflect on its journey as BMW Group's newest IT Hub. Employees got the chance to connect with the Cluster Heads and expats responsible for delivery operations, as well as meet the key leaders steering corporate functions like Compliance, Marketing, HR, and Finance. This allowed for a deeper understanding of our structure and delivery model. ",
          imgAlt: "TownHall image",
          imgSrc: "/assets/images/jointVenture.webp",
        }
      ]
    }
  ];


  getNews(lang: string = null): NewsItemDto[] {
    if (!lang) {
      this.translateService.currentLang;
    }
    let res = this.items.find(x => x.lang == lang);
    if (!res) {
      res = this.items.find(x => x.lang == 'en');
    }
    return res.data;
  }
}

import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private _supportedLanguages: string[] = ['en', 'ro', 'de'];

    constructor(private translateService: TranslateService,
                private router: Router) {

    }

    setDefaultLang() {
        this.translateService.setDefaultLang('en');
        this.translateService.use('en');
    }

    currentLang() {
        let currLang = this.translateService.currentLang;
        if (!currLang || !this._supportedLanguages.includes(currLang))
            currLang = 'en';
        return currLang;
    }

    getLangRoute(route: string) {
        return '/' + this.translateService.currentLang + '/' + route;
    }

    useLanguage(lang: string) {
        if (!lang || !this._supportedLanguages.includes(lang))
            lang = 'en';
        this.translateService.use(lang);
    }

    changeLanguage(lang: string) {
        localStorage.setItem('_language', lang);
        const currentRoute = this.router.url.split('/').slice(2).join('/'); // Get the path after `/:lang/`
        this.router.navigate([`/${lang}/${currentRoute}`]);
    }
}

import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {AboutRoute, CareersRoute, ContactRoute, CultureRoute, HomeRoute, NewsRoute} from "../../app.globals";
import {isPlatformServer, NgClass} from "@angular/common";
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    NgClass
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})

export class NavbarComponent implements OnInit {
  protected readonly ContactRoute = ContactRoute;
  protected readonly HomeRoute = HomeRoute;
  protected readonly AboutRoute = AboutRoute;
  protected readonly CultureRoute = CultureRoute;
  protected readonly CareersRoute = CareersRoute;
  protected readonly NewsRoute = NewsRoute;
  public lang = this.translateService.currentLang;

  constructor(private translateService: TranslateService,
              public languageService: LanguageService,
              @Inject(PLATFORM_ID) private platformId: any,
              private router: Router) {
    this.translateService.onLangChange.subscribe((params: any) => {
      this.lang = params.lang;
    });
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) return;

    document.querySelector('#navbarSideCollapse')?.addEventListener('click', () => {
      document.querySelector('.offcanvas-collapse')?.classList.toggle('open');
    });
  }

  changeLanguage(language: string) {
    this.languageService.changeLanguage(language);
  }
}

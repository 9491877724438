import {Component, Input, OnInit, TrackByFunction} from '@angular/core';
import {JobGroupDto} from "../../../contracts/job-group.dto";
import {NgForOf, NgIf} from "@angular/common";
import {JobItemDto} from "../../../contracts/jobItemDto";
import {TranslateModule} from "@ngx-translate/core";
import {Router} from '@angular/router';
import {ExpandStoreDto} from "../../../contracts/expand-store.dto";
import {LanguageService} from "../../../services/language.service";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-job-group',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    TranslateModule,CommonModule
  ],
  templateUrl: './job-group.component.html',
  styleUrl: './job-group.component.scss'
})
export class JobGroupComponent implements OnInit {
  @Input() job: JobGroupDto;
  expanded: boolean;
  private expandKey = '_jobExpandedKey';

  constructor(private router: Router,
              private langService: LanguageService) {
  }

  ngOnInit() {
    console.log(this.job.name)
    const expandStoreString = localStorage.getItem(this.expandKey);
    if (expandStoreString != null) {
      try {
        const expandStore = JSON.parse(expandStoreString) as ExpandStoreDto;
        if (expandStore.key == this.job.name)
          this.expanded = expandStore.isExpanded;
      } catch {
      }
    }
  }

  expand(job: JobGroupDto, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.expanded = !this.expanded;

    const expandStore: ExpandStoreDto = {
      key: job.name,
      isExpanded: this.expanded
    };
    localStorage.setItem(this.expandKey, JSON.stringify(expandStore));
  }

  trackByFn: TrackByFunction<JobItemDto> = (ix, it) => {
    return it.title;
  }

  async readMore(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = this.job.url;
  }

  getFrontStyle() {
    return {
      'background-image': `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('${this.job.image}')`,
      'background-size': 'cover',
      'background-position': 'center'
    };
  }

  getBackStyle() {
    return {
      'background-image': `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0,0.7)), url('${this.job.image}')`
    };
  }

  getFirstJob() {
    return this.job.name ==="Software Development and Operations";
  }
}

<!--<div class="card job-card">
  <div class="card-body" (click)="expand(job, $event)">
    <div class="title">
      <h3>{{ job.name }}</h3>
      <a aria-label="Jobs" class="call-to-action-link-btn careers-carret" (click)="expand(job, $event)"
         style="width: auto">
        <img src="/assets/images/icons/down-arrows.svg"
             style="width: 25px;height:auto"
             alt="See more" />
      </a>
    </div>
  </div>
</div>
<div class="d-flex w-100 justify-content-center">
  <div class="job-item-container" *ngIf="expanded">
    <div class="content d-flex justify-content-between">
      <div class="card job-card-item">
        <div class="card-body">
          <div [innerHTML]="job?.description"></div>
        </div>
        <div class="d-flex justify-content-end pe-3 mb-3">
          <button class="call-to-action-link-btn" (click)="readMore($event)" style="min-width: 150px">
            FIND YOUR ROLE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>-->
<!--//////////////////////////-->

<div class="box-item-flip">
  <div class="flip-box">
    <div class="flip-box-front" [ngStyle]="getFrontStyle()">
      <div class="inner">
        <h2 class="d-flex justify-content-center">
          <b class="new-line flip-box-title">{{ job.name }} </b>
        </h2>
      </div>
    </div>
    <div class="flip-box-back" [ngStyle]="getBackStyle()" (click)="readMore($event)">
      <div class="inner color-white">
        <p class="px-3 new-line" [ngClass]="{'first-job': getFirstJob()}" [innerHTML]="job?.description">
        </p>
      </div>
    </div>
  </div>
</div>
<button class="call-to-action-link-btn role-button" (click)="readMore($event)">
  FIND YOUR ROLE  <span> ></span>
</button>
